.mobile_roadmap.roadMap{
  padding-top: .8rem;
}

.roadMap .mobile_title{
  margin-bottom: .8rem;
}
.mobileTimeline{
  padding: 0 .3rem;
  margin-bottom: 1.5rem;
  background: url(../../assets/images/roadmap_bg.png) no-repeat center;
  background-size: contain;
}

.mobileTimeline .timeline-item{
  display: flex;
  align-items: stretch;
}

.mobileTimeline .timeline-item .time,.mobileTimeline .timeline-item .content{
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.mobileTimeline .timeline-item .time::after{
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #6146FF;
}

.mobileTimeline .timeline-item .content::before{
  content: '';
  display: block;
  width: .32rem;
  height: .32rem;
  border-radius: 50%;
  box-sizing: content-box;
  border: .18rem solid #000;
  position: absolute;
  left: 0;
  top: .6rem;
  background-color: #6146FF;
  transform: translateX(-50%) translateY(-50%);
}


.mobileTimeline .timeline-item .time{
  width: 2.2rem;
  padding-right: .4rem;
  align-items: flex-end;
  padding-top: .4rem;
}

.mobileTimeline .timeline-item .time >div{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.mobileTimeline .timeline-item .content{
  flex: 1;
  padding-left: .4rem;
  padding-top: .5rem;
  font-size: .16rem;
  line-height: .26rem;
  font-family: ARFont;
  margin-bottom: .8rem;
}

/* .mobileTimeline .timeline-item .content p::before{
  content: '-';
} */

.mobileTimeline .timeline-item:last-child .content{
  margin-bottom: .2rem;
}

.mobileTimeline .timeline-item .year_box{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* margin-top: -1rem; */

}

.mobileTimeline .timeline-item .num{
  color: #000;
  font-size:1.36rem;
  text-shadow: 1px 1px #6146FF, -1px -1px #6146FF,
                 1px -1px #6146FF, -1px 1px #6146FF;
  font-family: WMFont;
  line-height: 1;
  display: none;
}

.mobileTimeline .timeline-item .year{
  font-family: WMFont;
  font-size: .2182rem;
  color: #A9AAAE;
}

.mobileTimeline .timeline-item .stage{
  font-family: WMFont;
  font-size: .2rem;
  line-height: 2;
}

.mobileTimeline .timeline-item .stage.name{
  font-family: MWFont;
  text-transform: uppercase;
}

.indentation{
  padding-left: .16rem;
}

.roadMapBg{
  width: 3.5rem;
  position: absolute;
  top: 10%;
  left: 10%;
}

.scen{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}