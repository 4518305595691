.mobileFooter{
  padding-bottom: .56rem;
}
.mobileFooter .foot_con{
  display: flex;
  grid-gap: 2.5vw;
  gap: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobileFooter .foot_con .name{
  font-family: MWFont;
  font-size: .18rem;
  text-align: center;
}

.mobileFooter .foot_con .icon_item{
  display: flex;
  grid-gap: .1rem;
  gap: .1rem;
  align-items: center;
}

.mobileFooter .foot_con .icon_item .item{
  width: .8rem;
}