.team{
  overflow: hidden;
  padding-bottom: 1.5rem;
}

.teamUl{
  display: flex;
  grid-gap: 1.5vw;
  gap: 1.5vw;
  width: 100%;
  padding: 0 2rem;
  /* box-sizing: content-box; */
}

.teamUl li{
  flex: 1;
  overflow: hidden;
}

.teamUl .img_box{
  max-width: 2rem;
  max-height: 2rem;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  border: .02rem solid #fff;
}

.teamUl .img_box img{
  width: 100%;
  height: 100%;
  transition: all .3s linear;
  transform: scale(1.03);
}
.teamUl li:hover .img_box img{
  transform: scale(1.2);
}

.teamUl .name{
  font-size: .20rem;
  font-family: ARFont;
  text-align: center;
  color: #fff;
  word-break: break-all;
  margin-top: .2rem;
  line-height: 1.2;
  font-weight: 900;
}

.teamUl .introduce{
  font-size: .18rem;
  color: #fff;
  font-family: BLFont;
  text-align: center;
}