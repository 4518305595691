.notification_box .notification {
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  padding: 16px 24px;
  overflow: hidden;
  color: #e0e7ff;
  font-size: .16rem;
  position: fixed;
  right: .2rem;
  line-height: 1.2;
  word-wrap: break-word;
  background-color: rgb(42, 39, 63);
  /* border: 1px solid #59546d; */
  border-radius: 0.08rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 9999;
}

.notification_box .close {
  position: absolute;
  top: .1rem;
  right: .1rem;
  line-height: 1;
  font-size: .16rem;
  cursor: pointer;
  /* color:rgba(0, 0, 0, 0.45); */
  color: #e0e7ff;
}

.notification_box .content {
  margin: 0;
  font-size: .16rem;
  /* color:rgba(0, 0, 0, 0.85); */
  color: #e0e7ff;
  overflow-wrap: break-word;
  text-size-adjust: 100%;
  font-family: DDFont;
}

.notification_box .fade-enter,
.fade-appear {
  opacity: 0;
  right: -200rem;
}

.notification_box .fade-enter-active,
.fade-appear-active {
  opacity: 1;
  right: .2rem;
  transition: all .35s linear;
}

.notification_box .fade-enter-done {
  opacity: 1;
}

.notification_box .fade-exit {
  opacity: 1;
}

.notification_box .fade-exit-active {
  opacity: 0;
  transition: all .35s ease-in;
}

.notification_box .fade-exit-done {
  opacity: 0;
}

.flexBox {
  display: flex;
  align-content: center;
}

.notification_box.success .notification {
  background-color: #20c882;
}

.notification_box.failed .notification {
  background-color: #ff4427;
}