.story_screen {
  padding: 1rem 0 0;
  position: relative;
}

.story_screen .contain {
  z-index: 1;
  pointer-events: none;
  padding: 0 2rem;
}

.story_content .des {
  margin-bottom: .2rem;
  text-align: center;
}

.story_screen .bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.swiper_contain {
  margin: auto;
  position: relative;
  margin-top: .5rem;
  /* padding: 0 1rem; */
}

.swiper_contain .swiper,
.swiper_contain_copy .swiper {
  padding: .5rem 1rem 1rem;
  margin: 0 -1rem;
  position: relative;
}

.swiper_contain .swiper::before {
  content: '';
  width: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-image: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 5;
}

.swiper_contain .swiper::after {
  content: '';
  width: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-image: linear-gradient(to left, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 5;
}


.swiper_contain .swiper-slide {
  text-align: center;

}

.swiper_contain .swiper-slide .swiper_img {
  border-radius: .1rem;
  /* box-shadow: 0rem 0rem .1rem .03rem #f7f7f7; */
}

.swiper_contain .swiper-slide .swiper_img img {
  border-radius: .1rem;
}





.swiper_contain_copy {
  /* width: 90%; */
  /* max-width: 1440px; */
  margin: auto;
  position: relative;
  overflow: hidden;
  margin-top: .5rem;
}