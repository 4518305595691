@font-face {
  font-family: BLFont;
  src: url('./Barlow-Light.ttf');
}

@font-face {
  font-family: BMFont;
  src: url('./Barlow-Medium.ttf');
}

@font-face {
  font-family: BBFont;
  src: url('./Barlow-Bold.ttf');
}

@font-face {
  font-family: MWFont;
  src: url('./ModernWarfare-8MM6z.ttf');
}

@font-face {
  font-family: WMFont;
  src: url('./Widescreen-MixedBold.ttf');
}


@font-face {
  font-family: ARFont;
  /* src: url('./Arkhip-Regular.ttf'); */
  src: url('./Sarpanch-Regular.ttf');
}

@font-face {
  font-family: SRFont;
  src: url('./Sarpanch-Regular.ttf');
}

@font-face {
  font-family: DDBFont;
  src: url('./D-DINExp-Bold.ttf');
}

@font-face {
  font-family: DDFont;
  src: url('./D-DINExp.ttf');
}

@font-face {
  font-family: ARKHFont;
  src: url('./Arkhip-Regular.ttf');
}