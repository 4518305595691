.characters .mobile_title{
  margin-bottom: .8rem;
}

.charactersList{
  padding: 0 .2rem;
  opacity: 1;
  margin-top: .6rem;
  position: relative;
}

.breathModth{
  position: absolute;
  left: 0;
  top: -.3rem;
  width: 100%;
  height: 112%;
  background-color: rgba(0, 0, 0, .1);
  z-index: 3;
}

.breathModth .point{
  position: absolute;
  left: 20%;
  bottom: 30%;
  width: .5rem;
  height: .5rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation-timing-function: ease-in-out;
  animation-name: breathe;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes breathe {
  0% {
      opacity: .4;
      box-shadow: 0 1px 2px rgba(255, 255, 255, 0.4), 0 1px 1px rgba(255, 255, 255, 0.1) inset;
  }

  100% {
      opacity: 1;
      border: 1px solid rgba(255, 255, 255, 0.7);
      box-shadow: 0 1px 30px #ffffff, 0 1px 20px #ffffff inset;
  }
}

.charactersList .ul_box {
  display: flex;
  width: 100%;
}

.charactersList .list_item {
  position: relative;
  flex: 1 1;
  display: flex;
  height: 2.6rem;
  margin: 0 -0.15rem;
  overflow: hidden;
  align-items: stretch;
  transition: all .3s linear;
}

/* .charactersList .list_item:nth-child(2) {
  transform: translateY(-0.3rem) translateX(.05rem);
  transition: transform .3s linear;
  z-index: 2;
} */

.charactersList .list_item.active {
  transform: translateY(-0.3rem) translateX(.05rem);
  z-index: 2;
}

.charactersList .list_item::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  pointer-events: none;
  opacity: 1;
  transition: opacity .5s linear;
}

.charactersList .list_item.active::after{
  opacity: 0;
}

.charactersList .list_item .img_contain {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  justify-content: center;
}

.charactersList .list_item .bg {
  position: absolute;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  background-size: cover;
}

.charactersList .list_item .img_contain img {
  position: relative;
  z-index: 2;
  height: 80%;
  /* transform: rotateY(180deg); */
}

.charactCont{
  width: 100%;
  overflow: hidden;
}

.charactCont_list{
  width: 300vw;
  /* display: flex; */
  display: none;

}

.charactCont_list .charactCont_item{
  padding: 0 .3rem;
  width: 100vw;
}

.charactCont_list .charactCont_item .name {
  font-size: .44rem;
  color: #6146FF;
  font-family: MWFont;
  position: relative;
  line-height: 2.1;
}

.charactCont_list .charactCont_item .name::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 58%;
  width: 0.7rem;
  height: 0.05rem;
  background: #fff;
  -webkit-clip-path: polygon(10% 1%, 100% 0%, 90% 100%, 0% 100%);
  clip-path: polygon(10% 1%, 100% 0%, 90% 100%, 0% 100%);
}

.charactCont_list .charactCont_item .name:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  border-bottom: 1px solid #fff;
}

.charactCont_list .charactCont_item .skill_title {
  font-size: .32rem;
  color: #FFFFFF;
  font-family: MWFont;
  line-height: 2;
  position: relative;
  margin-bottom: 0.2rem;
}

.charactCont_list .charactCont_item .skill_title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35%;
  border-bottom: 1px solid #fff;
}

.charactCont_list .charactCont_item .story {
    font-size: .18rem;
    color: #FFFFFF;
    font-family: ARFont;
    line-height: 1.4;
    padding: 0.2rem 0 0.3rem;
}

.charactCont_list .charactCont_item .skill_item {
  display: flex;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  align-items: center;
  margin-bottom: 0.2rem;
}

.charactCont_list .charactCont_item .skill_name {
  font-size: .18rem;
  color: #FFFFFF;
  font-family: ARFont;
  line-height: 1.4;
  margin-left: .1rem;
}