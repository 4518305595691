/*  */
.Characters{
  padding-bottom: 1rem;
}

.charact_content{
  position:relative;

}
.charaters_list{
  padding: 0 3rem;
  transition: padding .5s linear;
  opacity: 1;
}

.charaters_list.listHide{
  visibility: hidden;
  opacity: 0;
}

.charaters_list.padHide{
  padding: 0;
}

.ul_box{
  display: flex;
  /* align-items: stretch; */
  width: 100%;
  /* align-items: center;
  justify-content: center; */
}

.charaters_list .list_item{
  position: relative;
  /* flex: 1; */
  display: flex;
  width: 40%;
  height: 6rem;
  margin: 0 -0.3rem;
  overflow: hidden;
  align-items: stretch;
  transition: opacity .01s linear,width .3s linear;
  max-height: 6rem;
}

.charaters_list .list_item.active{
  height: 6rem;
  width: 100vw;
  align-items: center;
  margin: 0;
}

.padHide.charaters_list .list_item:not(.active){
  width: 0;
  opacity: 0;
  margin: 0;
}

.charaters_list .list_item:nth-child(2){
  transform: translateY(-0.8rem) translateX(.12rem);
  transition: transform .3s linear;
}

.charaters_list .list_item.active:nth-child(2){
  transform: translateY(0) translateX(0);
}


.charaters_list .list_item.active .img_model{
  display: none;
}

.charaters_list .list_item .img_model{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.363);
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  clip-path:  polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s linear;
}

.charaters_list .list_item:hover .img_model{
  background-color: transparent;
}

.charaters_list .list_item:hover .img_model span.liuguang{
  visibility:visible;
  opacity: 1;
}

.charaters_list .list_item .text_contain{
  width: 0;
  transition: width .2s linear;
  padding: 0 .9rem;
  display: none;
}

.charaters_list .list_item.active .text_contain{
  width: calc(100% - 6rem);
  display: block;
}

.charaters_list .list_item .text_contain >*{
  transform: translateX(-1.5rem);
  visibility: hidden;
  transition: transform 0.8s linear, opacity .9s linear;
  width: 100%;
  opacity: 0;
}

.charaters_list .list_item.active.showCont .text_contain *{
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
}

.charaters_list .list_item .name{
  font-size: .44rem;
  color: #6146FF;
  font-family:  MWFont;
  position: relative;
  line-height: 2.1;
}

.charaters_list .list_item .name:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  border-bottom: 1px solid #fff;
}

.charaters_list .list_item .name::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 58%;
  width: .7rem;
  height: .05rem;
  background: #fff;
  -webkit-clip-path: polygon(10% 1%, 100% 0%, 90% 100%, 0% 100%);
  clip-path:  polygon(10% 1%, 100% 0%, 90% 100%, 0% 100%);
}

.charaters_list .list_item .story{
  font-size: .18rem;
  color: #FFFFFF;
  font-family: ARFont;
  line-height: 1.4;
  padding: .2rem 0 .3rem;
}



.charaters_list .list_item .skill_title{
  font-size: .32rem;
  color: #FFFFFF;
  font-family: MWFont;
  line-height: 2;
  position: relative;
  margin-bottom: .2rem;
}

.charaters_list .list_item .skill_title:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35%;
  border-bottom: 1px solid #fff;
}

.charaters_list .list_item .skill_item{
  display: flex;
  gap: .1rem;
  align-items: center;
  margin-bottom: .2rem;
}

.charaters_list .list_item .skill_name{
  font-size: .18rem;
  color: #FFFFFF;
  font-family: ARFont;
  line-height: 1.4;
}

span.liuguang {
  visibility: hidden;
  opacity: 0;
  font-size: .24rem;
  /*设置渐变背景，最好设置为能无缝拼接的渐变*/
  background-image: -webkit-linear-gradient(left, rgb(255, 255, 255), rgba(255, 255, 255, 0.3) 25%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0.3) 75%, rgb(255, 255, 255) 100%);
  /*文字填充色为透明*/
  -webkit-text-fill-color: transparent;
  /*背景剪裁为文字，相当于用背景填充文字*/
  background-clip: text;
  /*将背景拉长一倍，给予移动变化空间，用作流光效果*/
  background-size: 200% 100%;
  animation: light 1s infinite linear;
  font-weight: bolder;
  font-family: 'MWFont';
  transition: all .3s linear;
  display: flex;
  align-items: center;
}
@keyframes light {
  0%  {
      background-position: 0 0;
  }
  100% {
      background-position: -100% 0;
  }
}


.close_block{
  display: none;
  position: absolute;
  right: .8rem;
  top: -0.4rem;
  width: .4rem;
  height: .4rem;
  cursor: pointer;
  background: url('../assets/images/close.png') no-repeat center;
  background-size: cover;
  /* transition: all 0.3s linear; */
}
.padHide .close_block{
  display: block;
}

.close_block:hover{
  background-image: url('../assets/images/close_active.png');
}




.charaters_list .list_item .img_contain{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  clip-path:  polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  justify-content: center;
  overflow: hidden;
}


.charaters_list .list_item .img_contain img{
  position: relative;
  z-index: 2;
  height: 86%;
  /* transform: rotateY(180deg); */
  transition: transform .5s linear;

}

 .charaters_list .list_item:not(.active):hover .img_contain img{
   transform: scale(1.2);
 }

.charaters_list .list_item.active .img_contain{
  width: 6rem;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  clip-path:  polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
}



.charaters_list .list_item .bg{
  position: absolute;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  clip-path:  polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  background-size: cover;
  transition: all .5s linear;
}



.charaters_list .list_item.active .bg{
  left: -22%;
}

