#root {
  width: 100%;
  /* min-height: 100vh; */
  background-color: black;
  color: white;
  user-select: none;
  /* overflow-x: hidden; */
}

#root .contentRegular {
  font-family: 'BLFont';
}

#root .contentBold {
  font-family: 'BMFont';
}

#root .contentBolder {
  font-family: 'ARFont';
}

#root .titleFont {
  font-family: 'MWFont';
}

#root .numberFont {
  font-family: 'WMFont';
}

img {
  display: block;
}


.contain{
  max-width: 1640px;
  margin: auto;
  position: relative;
}



.sticky{
  width: 100%;
  overflow: hidden;
  /* background: url(./assets/images/screen_home_bg.png) center no-repeat; */
  background-color: #000;
  /* background-size: 100% 100%; */
  height: 100vh;
}

.preserve_box{
  position: relative;
  width: 100%;
  height: 100vh;
} 

.preserve_box .banner-bg{
  position: absolute;
  left: 50%;
  top: 48%;
  /* transform: translate(-50%, -50%); */
  z-index: 1;
}

.preserve_box .glass{
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%, -50%); */
}

.banner2-bg{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mobile_title{
  font-family: MWFont;
  font-size: .26rem;
  color: #fff;
  line-height: .3rem;
  text-align: center;
  margin-bottom: .40rem;
}

.mobile_block{
  padding: .32rem 0;
}