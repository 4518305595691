.mint_com {
    width: 100%;
    height: 100vh;
    background: url(../assets//mint/mint_bg.png) no-repeat center;
    background-size: contain;
    position: relative;
}

.mint_com .bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.mint_model {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mint_model .title {
    font-family: MWFont;
    font-size: 1rem;
    margin: 0;
    line-height: 1.1;
}

.timeBox {
    font-size: 1.8rem;
    line-height: 1;
    font-family: DDBFont;
}

.mint_mun {
    line-height: 1;
    font-size: .6rem;
    margin-top: .6rem;
    font-family: DDBFont;
}

.mintBtn {
    margin-top: .4rem;
}

.mint_btn {
    width: 6.5rem;
    border: none;
    line-height: 1;
    color: #000;
    border: 0.04rem solid #AEA0FF;
    background-color: #AEA0FF;
    font-family: DDBFont;
    display: flex;
    align-items: center;
    justify-content: center;
    height: .66rem;
    font-size: .32rem;
    cursor: pointer;
    transition: all .3s linear;
}

.mint_btn:hover,
.mint_btn.loading {
    background-color: #000;
    border-color: #AEA0FF;
    color: #AEA0FF;
}

.mint_btn:disabled {
    cursor: pointer;
    border-color: #FF4327;
    background-color: #3E0B0B;
    color: #FF4327;
    cursor: not-allowed;
}

.fromGroup input,
.fromGroup button {
    border: none;
}

.fromGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;
    font-family: DDFont;
}

.fromGroup .item {
    padding: 0 .2rem;
}

.inputGroup {
    text-align: center;
    border: .01rem solid #aea0ff80;
    width: 6.5rem;
    background-color: rgba(0, 0, 0, 0.8);
    line-height: 1;
    display: flex;
    box-sizing: border-box;
}

.fromGroup .item .inputGroup button {
    width: .66rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .4rem;
    color: rgb(255 255 255);
}

.fromGroup .item .inputGroup input {
    flex: 1;
    min-width: 1px;
    height: .66rem;
    font-family: DDBFont;
    text-align: center;
    font-size: .32rem;
    color: rgba(255, 255, 255);
}

.fromGroup .item .inputGroup input:focus {
    border: none;
    outline: none;
}

.fromGroup .lable {
    font-size: .24rem;
    margin-bottom: .15rem;
    text-align: left;
}


/* // loading */

.me-2 {
    margin-right: 0.25rem;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

.spinner-border {
    display: inline-block;
    width: .3rem;
    height: .3rem;
    border: 0.28em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
    100% {
        transform: rotate(360deg);
    }
}