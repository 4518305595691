.gameTitle{
  font-size: .18rem;
  color: #6146FF;
  font-family: MWFont;
  text-align: center;
}

.flexColumn{
  flex-direction: column;
}

.earnSwiper {
  padding: 0 .3rem;
}


/* .earnSwiper .img_box{
  padding-top: 100%;
  width: 100%;
  position: relative;
} */

/* .earnSwiper .img_box .video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .earnSwiper .item_contain .ord {
  border: 2px solid #979797;
  width: .8rem;
  height: .8rem;
  margin: auto;
  margin-bottom: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: WMFont;
  font-size: .32rem;
} */

.earnSwiper .item_contain .ord{     
  border: 1px solid #979797;
  width: 1.2rem;
  height: .6rem;
  margin: auto;
  margin-bottom: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 50%; */
  font-family: WMFont;
  position: relative;
}

.earnSwiper .item_contain .ord::before,
.earnSwiper .item_contain .ord::after {
    content: "";
    position: absolute;
    width: 0.3rem;
    height: .2rem;
}
  
.earnSwiper .item_contain .ord::before {
    top: -0.04rem;
    left: -0.04rem;
    border-top: 0.04rem solid #fff;
    border-left: 0.04rem solid #fff;
}
  
.earnSwiper .item_contain .ord::after {
    right: -0.04rem;
    bottom: -0.04rem;
    border-bottom: .04rem solid #fff;
    border-right: 0.04rem solid #fff;
}



.earnSwiper .item_contain .text_box h1 {
  font-family: ARFont;
  font-size: .24rem;
  text-align: center;
}

.game{
  margin-top: 1rem;
}

.gameSwiper{
  margin-top: .45rem;
  padding: 0 .3rem;
}

.gameSwiper .play_item{
  margin-bottom: .3rem;
}

.gameSwiper .title{
  font-size: .18rem;
  line-height: .21rem;
  display: none;
}

.gameSwiper .text_box{
  padding: .32rem;
}

.gameSwiper .text_box .name{
  font-family: MWFont;
  font-size: .18rem;
  color: #fff;
  line-height: .21rem;
  margin-bottom: .12rem;
}

.gameSwiper .text_box .disc{
  font-family: ARFont;
  font-size: .18rem;
  color: #B9B9B9;
  line-height: .23rem;
}

.mt_20{
  margin-top: .2rem;
}