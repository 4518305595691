.timeline{
  padding: 0 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  background: url(../../assets/images/roadmap_bg.png) no-repeat center;
  background-size: cover;
}

.timeline .timeline-item{
  display: flex;
  align-items: stretch;
}

.timeline .timeline-item .time,.timeline .timeline-item .content{
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* .timeline .timeline-item .time::after{
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #6146FF;
} */

.timeline .timeline-item .content::before{
  content: '';
  display: block;
  width: .32rem;
  height: .32rem;
  border-radius: 50%;
  box-sizing: content-box;
  border: 0.04rem solid transparent;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #6146FF;
  transform: translateX(-50%) ;
}

.timeline .timeline-item.active .content::before{
  border-color: #fff;
}


.timeline .timeline-item .time{
  padding-right: .7rem;
  align-items: flex-end;
}

.timeline .timeline-item .time >div{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.timeline .timeline-item .content{
  padding-left: .7rem;
  padding-bottom: 1rem;
  font-size: .24rem;
  line-height: 2;
  font-family: ARFont;
  margin-bottom: .8rem;
}

/* .timeline .timeline-item .content p::before{
  content: '-';
} */

.timeline .timeline-item .year_box{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* margin-top: -1rem; */

}

.timeline .timeline-item .num{
  color: #000;
  font-size:1.36rem;
  text-shadow: 1px 1px #6146FF, -1px -1px #6146FF,
                 1px -1px #6146FF, -1px 1px #6146FF;
  font-family: WMFont;
  line-height: 1;
  display: none;
}

.timeline .timeline-item .year{
  font-family: WMFont;
  font-size: .44rem;
  color: #A9AAAE;
  margin-top: .2rem;
}

.timeline .timeline-item .stage{
  font-family: WMFont;
  font-size: .6rem;
  line-height: 1;
}

.timeline .timeline-item .stage.name{
  font-family: MWFont;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}

.indentation{
  padding-left: .16rem;
}

.roadMapBg{
  width: 3.5rem;
  position: absolute;
  top: 10%;
  left: 10%;
}

.scen{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.roadMap .roadmodel_box{
  position: absolute;
  left: 0;
  width: 100%;
  height: 300px;
  background: linear-gradient(rgba(0,0,0,1), transparent);
}

.roadMap .model_bottom{
  bottom: 0px;
  background: linear-gradient( transparent, rgba(0,0,0,1));
}

.timeline .timeline__line{
  position: absolute;
  width: 0.04rem;
  bottom: 2.55rem;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  overflow: hidden;
  left: 50%;
  margin: 0 !important;
  background-color: #757575;
  width: 10px;
  border-style: solid;
  border-width: 0.04rem;
  border-radius:0.5rem;
  border-color: #757575;
}

.timeline__line-progress {
  background-color: #FFFFFF;
  /* height: 20%; */
}