.game_screen {
  /* padding: 1.7rem 0; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.game_screen .sub_title {
  color: #6146FF;
  font-size: .44rem;
  text-align: center;
  font-family: MWFont, 'Courier New', Courier, monospace;
  /* margin-top: .84rem; */
  line-height: 1.5;
}

.game_screen .scrollmagic-pin-spacer {
  flex: 1;
  box-sizing: border-box;
}



.game_screen .game_scroll_box .item {
  width: 100vw;
  /* height: 100%; */
  /* height: calc(100vh - 2.74rem); */
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .3rem;
}

.play_list {
  display: flex;
  width: 100%;
  grid-gap: 2vw;
  gap: 2vw;
  padding: 0 .5rem;
}

.play_list .play_item {
  flex: 1;
  overflow: hidden;
}

.play_list .play_item .img_box {
  overflow: hidden;
}

.play_list .play_item .img_box img {
  width: 100%;
  transition: all .3s linear;
}

.play_list .play_item:hover .img_box img {
  width: 100%;
  transform: scale(1.1);
}

.play_list .play_item .text_box {
  text-align: left;
  padding: .2rem;
  padding-bottom: 0;
  background-color: #23252F;
}

.play_list .play_item .text_box .title {
  font-size: .3rem;
  font-family: MWFont;
  line-height: 1.5;
  margin-bottom: .1rem;
  display: none;
}

.play_list .play_item .text_box .name {
  font-size: .18rem;
  font-family: MWFont;
  line-height: 1.2;
  margin-bottom: .08rem;
}

.play_list .play_item .text_box .disc {
  font-size: .18rem;
  font-family: ARFont;
  line-height: 1.2;
  padding-bottom: .2rem;
  color: #B9B9B9;
}

.game_scroll_box .title {
  margin-top: .3rem;
}

.game_scroll_box .item_contain {
  height: 100%;
  padding: 0 2vw;
  display: flex;
}

.flex {
  display: flex;
  align-items: center;
}

.game_scroll_box .item_contain .img_box {
  width: 80%;
}


.game_scroll_box .item_contain .ord {
  border: 1px solid #979797;
  width: 1.5rem;
  height: .8rem;
  margin: auto;
  margin-bottom: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 50%; */
  font-family: WMFont;
  position: relative;
}

.game_scroll_box .item_contain .ord::before,
.game_scroll_box .item_contain .ord::after {
  content: "";
  position: absolute;
  width: 0.4rem;
  height: .3rem;
}

.game_scroll_box .item_contain .ord::before {
  top: -0.03rem;
  left: -0.03rem;
  border-top: 0.06rem solid #fff;
  border-left: 0.06rem solid #fff;
}

.game_scroll_box .item_contain .ord::after {
  right: -0.03rem;
  bottom: -0.03rem;
  border-bottom: .06rem solid #fff;
  border-right: 0.06rem solid #fff;
}

.game_scroll_box .item_contain .text_box {
  font-family: ARFont;
}

.game_scroll_box .item_contain .text_box h1 {
  font-size: .32rem;
}

.game_scroll_box .item_contain .bagCoin {
  position: relative;
}

.game_scroll_box .item_contain .bag_box {
  width: 5rem;
  height: 3.5rem;
  margin: auto;
  position: relative;
  animation: test 3s linear;
  animation-iteration-count: infinite;
}

.game_scroll_box .item_contain .bagShadow {
  position: absolute;
  bottom: -40%;
  left: -10%;
  width: 120%;
}

.game_scroll_box .item_contain .coin_box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

@keyframes test {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(.9);
  }
}

.game_scroll_box .item_contain .coin_box .coin1 {
  width: .4rem;
  position: absolute;
  left: 30%;
  top: 30%;
  /* animation: coin1 1.5s cubic-bezier(0,.66,.28,.49);
  animation-iteration-count: infinite; */
  transition-timing-function: linear;
  transition-timing-function: cubic-bezier(.55, 0, .85, .36);
}

.game_scroll_box .item_contain .coin_box .coin2 {
  width: .5rem;
  transform: rotate(30deg) skew(.312rad);
  position: absolute;
  left: 63%;
  top: 40%;
}

.game_scroll_box .item_contain .coin_box .coin3 {
  width: .8rem;
  position: absolute;
  left: 45%;
  top: 30%;
}

@keyframes coin1 {
  0% {
    left: 30%;
    top: 30%;
  }

  30% {
    left: 10%;
    top: -30%;
    /* opacity: .7; */
  }

  100% {
    left: -30%;
    top: 60%;
    opacity: 0;
  }
}


.game_scroll_box .swiper-wrapper {
  align-items: center;
}

/* .title_box{
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
} */

.game_scroll_box .swiper-wrapper .swiper-slide {
  display: flex;
  align-items: flex-end;
}


.game_scroll_box .swiper-button-next,
.game_scroll_box .swiper-button-prev {
  display: block;
  opacity: 0;
  border-radius: 100px;
  transition: all 0.4s ease;
  line-height: 0;
  border-radius: 100px;
  font-size: 0;
  color: transparent;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  background-size: 0 auto;
  animation: pulse 1.5s 0s infinite normal ease forwards;
  background-image: url(../../assets/images/arror3.svg);
  background-repeat: no-repeat;
  transform: rotateZ(-90deg);
  /* top: 65%; */
}

.game_scroll_box .swiper-button-prev {
  transform: rotateZ(90deg);
}

.game_scroll_box .swiper-button-next::before,
.game_scroll_box .swiper-button-next::after,
.game_scroll_box .swiper-button-prev::before,
.game_scroll_box .swiper-button-prev::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/arror3.svg) no-repeat center top;
  background-size: 100% auto;
}

.game_scroll_box .swiper-button-next::after,
.game_scroll_box .swiper-button-prev::after {
  animation: pulse 1.5s 1s infinite normal ease forwards;
}

.game_scroll_box .swiper-button-next:before,
.game_scroll_box .swiper-button-prev::before {

  animation: pulse 1.5s 0.5s infinite normal ease forwards;
}

.game_scroll_box .swiper-button-prev.swiper-button-disabled,
.game_scroll_box .swiper-button-next.swiper-button-disabled {
  visibility: hidden;
}

@keyframes pulse {
  0% {
    opacity: 0;
    background-position: center top;
    background-size: 0 auto;
  }

  10% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    background-size: 75% auto;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    background-position: center bottom;
    background-size: 0 auto;
  }
}