.banner.mobileBanner {
  position: relative;
}

.mobileBanner .bg {
  height: 5rem;
  width: 120%;
  margin-left: -10%;
  /* overflow: hidden; */
  position: relative;
}

.mobileBanner .bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7));
}


.mobileBanner .bg-transfram {
  width: 100%;
  height: 100%;
  transform: rotateZ(-6deg);
  position: relative;
}

.mobileBanner .bg .bg-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobileBanner .bg .pc-marqueee {
  padding-top: 28%;
  width: 100%;
}

.mobileBanner .bg .pc-marqueee_top {
  background: url(../../assets/banner/banner_bg1.png) repeat-x center;
  background-size: auto 100%;
  animation: wall_scrollx 80s linear 0s infinite forwards;
}

.mobileBanner .bg .pc-marqueee_bottom {
  background: url(../../assets/banner/banner_bg2.png) repeat-x center;
  background-size: auto 100%;
  animation: wall_scrollx2 80s linear 0s infinite forwards;
}

.sceneText {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .34rem;
  font-family: MWFont;
  line-height: .39rem;
  z-index: 2;
  text-align: center;
}

.sceneImg {
  position: relative;
}

.sceneImg::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 40%, #000);
}

.banner.mobileBanner .ReNewPublic {
  height: auto;
}

.banner.mobileBanner .ReNewPublic .content {
  position: static;
}

.banner.mobileBanner .mobilmint .ContentAddress {
  width: auto;
  height: auto;
  border: none;
  padding: .06rem;
  position: fixed;
  top: 0.14rem;
  right: 0.6rem;
  z-index: 99;
  transform: inherit;
}

.banner.mobileBanner .ContentAddress .icon {
  width: .42rem;
  height: .42rem;
}

.banner.mobileBanner .ContentAddress .textCon {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  /* padding-right: .2rem; */
}

.banner.mobileBanner .ContentAddress .add,
.banner.mobileBanner .ContentAddress .pub {
  line-height: 1;
}

.banner.mobileBanner .ContentAddress .pub {
  margin-bottom: .06rem;
}

.banner.mobileBanner .model_box {
  position: relative;
  width: 100%;
  padding: 0 .2rem;
  transform: none;
  margin-top: .8rem;
  margin-bottom: .4rem;
  left: 0;
}

.banner.mobileBanner .model_box .video_box.ios {
  position: absolute;
  width: 100%;
  padding: 0 .2rem;
  height: 100%;
  right: 0;
  bottom: 0;
  border: none;
}

.model_box .video_box.pc {
  position: relative;
  width: 100%;
  padding: 0 .2rem;
  height: 100%;
  right: 0;
  bottom: 0;
  border: none;
}

.video_model .ant-modal-close {
  right: 0.3rem;
  top: 1rem;
}

.video_model .ant-modal-close .ant-modal-close-x {
  width: .42rem;
  height: .42rem;
}


.banner.mobileBanner .model_box .btn_pause {
  width: 0.95rem;
  height: 0.95rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}