@import '~antd/dist/antd.css';

*, :after, :before {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
}
html {
  -moz-tab-size:4;
  tab-size:4;
  line-height:1.15;
  -webkit-text-size-adjust:100%
}
body {
  margin:0;
  font-family:system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
hr {
  height:0;
  color:inherit
}
abbr[title] {
  -moz-text-decoration:underline dotted;
  text-decoration:underline dotted
}
b, strong {
  font-weight:bolder
}
code, kbd, pre, samp {
  font-family:ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size:1em
}
small {
  font-size:80%
}
sub, sup {
  font-size:75%;
  line-height:0;
  position:relative;
  vertical-align:baseline
}
sub {
  bottom:-.25em
}
sup {
  top:-.5em
}
table {
  text-indent:0;
  border-color:inherit
}
button, input, optgroup, select, textarea {
  font-family:inherit;
  font-size:100%;
  line-height:1.15;
  margin:0
}
button, select {
  text-transform:none
}
[type=button], [type=reset], [type=submit], button {
  -webkit-appearance:button
}

h1, h2, h3, h4, h5, h6, hr, p {
  padding:0;
  margin:0;
  line-height: 1.2;
  color: #fff;
}
button, input, optgroup, select, textarea {
  font:inherit;
  color:inherit;
  background:none
}

th {
  text-align:left
}
body {
  font-size:14px;
  line-height:20px;
  overflow-x: hidden;
  position: relative;
}

body.hiddenScroll{
  position: fixed;
  overflow-y:scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e0e7ff;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.BBFont{
  font-family: BBFont,'Courier New', Courier, monospace;
}

.mt2_5{
  margin-top: .25rem;
}

.title{
  font-family: MWFont,'Courier New', Courier, monospace;
  font-size: .72rem;
  text-transform: uppercase;
  margin: 0.6rem 0;
}

.des{
  font-family: ARFont,'Courier New', Courier, monospace;
  font-size: .2rem;
  letter-spacing: .016rem;
  line-height: 0.32rem;
}



.flex{
  display: -moz-box;
  display: flex;
  -moz-box-align: end;
}
.flex-16{
  align-items: flex-end;
  grid-gap: 16px;
  gap: 16px;
  padding: 0 16px;
}

.div-module_grow{
  display: block;
  -moz-box-flex: 1;
  flex: 1 0;
}


.text-left{
  text-align: left;
}

.text-righr{
  text-align: right;
}

.text-center{
  text-align: center;
}

.modal-open{
  overflow: hidden;
}

.ant-popover-inner,.ant-popover-arrow-content{
  background: rgba(255, 255, 255, 0.5);
  font-family: ARFont;
}