.Copywriting{
  padding: 0 .32rem;
  font-size: .18rem;
  font-family: ARFont;
}

.Copywriting p{
  line-height: .28rem;
}

.picWall{
  margin-top: .36rem;
}

.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
}

.marqueee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll 30s linear running infinite;
  animation-play-state: running;
  animation-delay: 0s;
  animation-direction: normal;
}


.second .marqueee{
  animation: ReverseScroll 30s linear running infinite;
}




@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes ReverseScroll{
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}


.imgBox{
  display: flex;
}

.imgBox .imgItem{
  width: 2.8rem;
  height: 2.8rem;
  border-radius: .1rem;
  overflow: hidden;
  margin-right: .2rem;
}


.bottom.imgBox{
  margin-top: .2rem;
}
