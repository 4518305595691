.faq_list{
  max-width: 8rem;
  margin: auto;
}

.faq_list .ant-collapse{
  background-color: transparent;
  color: #fff;
  border: none;
}

.faq_list .ant-collapse > .ant-collapse-item{
  margin-bottom: .2rem;
}

.faq_list .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color: #fff;
  font-size: .2rem;
  font-family: ARFont;
  border: 1px solid #fff;
  border-bottom: none;
}

.faq_list .ant-collapse-content{
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-bottom: none;
}

.faq_list .faqFont{
  font-family: ARFont;
}