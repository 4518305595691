.mint_com {
    width: 100%;
    height: 100vh;
    /* background: url(../assets//mint/mint_bg.png) no-repeat center;
    background-size: contain; */
    background-image: none;
    position: relative;
}

.mint_com .bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.mint_model {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mint_model .title {
    font-family: MWFont;
    font-size: 1rem;
    margin: 0;
    line-height: 1.1;
}

.mint_model .beginTime {
    margin-top: .8rem;
    font-size: .6rem;
    font-family: DDBFont;
}

.mint_model .hourBox {
    font-family: DDBFont;
    margin-top: 1.5rem;
    font-size: .6rem;
}

.openlink {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: .32rem;
    font-family: DDFont;
    margin-top: .5rem;
}

.openlink img {
    width: .4rem;
    margin-left: .15rem;
}


.fromGroup input,
.fromGroup button {
    border: none;
}

.fromGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;
    font-family: DDFont;
    width: 100%;
}

.fromGroup .item {
    padding: 0 .2rem;
    width: 100%;
}

.mobilmint .inputGroup {
    text-align: center;
    border: 1px solid #aea0ff80;
    max-width: 6.5rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    line-height: 1;
    display: flex;
    box-sizing: content-box;
    margin: auto;
    border-radius: 1rem;
}

.fromGroup .item .inputGroup button {
    width: .66rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .4rem;
    color: rgb(255 255 255);
}

.mobilmint .fromGroup .item .inputGroup input {
    flex: 1;
    min-width: 1px;
    height: .78rem;
    font-family: DDBFont;
    text-align: center;
    font-size: .32rem;
    color: rgba(255, 255, 255);
}

.fromGroup .item .inputGroup input:focus {
    border: none;
    outline: none;
}

.fromGroup .lable {
    font-size: .24rem;
    margin-bottom: .15rem;
    text-align: left;
}

.mintBtn {
    margin-top: .4rem;
    width: 100%;
}

.mint_btn {
    max-width: 6.5rem;
    width: 90%;
    border: none;
    line-height: 1;
    color: #000;
    border: 0.04rem solid #AEA0FF;
    background-color: #AEA0FF;
    font-family: DDBFont;
    display: flex;
    align-items: center;
    justify-content: center;
    height: .66rem;
    font-size: .32rem;
    cursor: pointer;
    transition: all .3s linear;
    margin: auto;
}

.mint_btn:hover,
.mint_btn.loading {
    background-color: #000;
    border-color: #AEA0FF;
    color: #AEA0FF;
}

.mint_btn:disabled {
    cursor: pointer;
    border-color: #FF4327;
    background-color: #3E0B0B;
    color: #FF4327;
    cursor: not-allowed;
}


/* // loading */

.me-2 {
    margin-right: 0.25rem;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

.spinner-border {
    display: inline-block;
    width: .3rem;
    height: .3rem;
    border: 0.28em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
    100% {
        transform: rotate(360deg);
    }
}



@media screen and (max-width: 800px) {
    .mint_model .title {
        font-size: .5rem;
    }

    .fromGroup .item {
        padding: 0 .3rem;
    }
}

.web3modal-modal-lightbox {
    transition: opacity 0.1s ease-in-out 0s;
    text-align: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin-left: -50vw;
    top: 0px;
    left: 50%;
    z-index: 2;
    will-change: opacity;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.web3modal-modal-lightbox * {
    box-sizing: border-box !important;
}

.web3modal-modal-lightbox .web3modal-modal-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-hitbox {
    position: absolute;
    inset: 0px;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 12px;
    margin: 10px;
    padding: 0px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    max-width: 800px;
    min-width: fit-content;
    max-height: 100%;
    overflow: auto;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper {
    width: 100%;
    padding: 8px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 0px;
    border: 1px solid rgba(195, 195, 195, 0.14);
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper:hover .web3modal-provider-container {
    background-color: rgba(195, 195, 195, 0.14);
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container {
    transition: background-color 0.2s ease-in-out 0s;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    padding: 24px 16px;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon {
    width: 45px;
    height: 45px;
    display: flex;
    border-radius: 50%;
    overflow: visible;
    box-shadow: none;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon img {
    width: 100%;
    height: 100%;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-name {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    margin-top: 0.5em;
    color: #0c0c0d;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-description {
    width: 100%;
    font-size: 18px;
    margin: 0.333em 0px;
    color: #a9a9bc;
}


@media screen and (max-width:500px) {
    .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card {
        max-width: 800px;
        grid-template-columns: 1fr;
    }

    .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container {
        padding: 1vw;
    }

    .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon {
        width: 8.5vw;
        height: 8.5vw;
    }

    .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-name {
        font-size: 5vw;
    }

    .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-description {
        font-size: 4vw;
    }
}


.mobilmint .ContentAddress {
    position: absolute;
    right: 50%;
    top: 10%;
    /* width: 3.5rem; */
    height: .66rem;
    transform: translateX(50%);
    background-color: #232033;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.04rem solid #AEA0FF;
    border-radius: 1rem;
}

.mobilmint .title {
    line-height: .8rem;
    font-size: .28rem;
    text-align: center;
    margin: 0;
    color: #AEA0FF;
}

.ContentAddress .icon {
    width: .54rem;
    height: .54rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141120;
    border-radius: 1rem;
}

.ContentAddress .icon img {
    width: 90%;
}

.ContentAddress .textCon {
    flex: 1;
    padding: 0 .1rem;
}

.ContentAddress .pub {
    font-size: .16rem;
    font-family: DDBFont;
    color: #fff;
}

.ContentAddress .add {
    font-size: .12rem;
    font-family: DDBFont;
    line-height: .18rem;
    color: #9794A0;
}

.changVideo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.changVideo video {
    max-width: 9.9rem;
    width: 90%;
}

.mobilmint .connectWalltBtn {
    height: 0.78rem;
    width: 90%;
}

.mobilmint .mintBtn {
    margin-top: 0.3rem;
}