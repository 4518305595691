.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: rgb(0, 0, 0);
  padding: .1rem .2rem;
}

.spec {
  flex: 1;
}


.flex {
  display: flex;
}

.mint {
  position: relative;
}

.block {
  display: block;
}

.align-center {
  justify-content: center;
  align-items: center;
}


.navList {
  margin-top: .8rem;
  padding: 0 .24rem;
}

.pad3 {
  padding: 3vw 0;
}

.navList a {
  width: 100%;
  color: #fff;
  font-size: .3rem;
  font-weight: 500;
  font-family: ARFont;
  letter-spacing: .03rem;
  padding: .3rem 0;
}

.navList .active a,
.navList .active,
.navList a:hover {
  color: #2bff98;
  text-decoration: none;
}

.navList .item {
  border-bottom: .01rem solid #D8D8D8;
}




.nav-model {
  position: fixed;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 6;
  transition: all 0.3s linear;
  padding-top: 0.6rem;
}

.nav-model.active {
  top: 0;
}

.nav-model .model-content {
  width: 100%;
  height: 100%;
}

.phoneNav {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.iconUl {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon ul li {
  width: .24rem;
  height: .02rem;
  background: #fff;
  margin: .04rem auto;
  transition: all 0.3s;
  border-radius: .19rem;
}

.icon ul:hover {
  cursor: pointer;
}

.iconUl.active li:nth-child(2) {
  opacity: 0;
}

.iconUl.active li:nth-child(1) {
  transform: translateY(.04rem) rotate(45deg)
}

.iconUl.active li:nth-child(3) {
  transform: translateY(-0.08rem) rotate(-45deg)
}

.header .navBtn {
  display: flex;
  align-items: center;
}