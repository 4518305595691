.mobileRaces{
  padding-bottom: .8rem;
  padding-top: 1rem;
}

.mobileRaces .racesList{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.1rem;
}

.mobileRaces .racesList .item{
  width: 100%;
  padding: .1rem;
}