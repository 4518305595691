.mobileTeam{
  overflow: hidden;
  padding-bottom: 1.5rem;
}

.mobileTeam .teamUl{
  display: flex;
  width: 100%;
  gap: .15rem;
  flex-wrap: wrap;
  padding: 0 .3rem;
  justify-content: space-between;
}

.mobileTeam .teamUl li{
  flex: none;
  width: calc(50% - .1rem);
  overflow: hidden;
  margin-bottom: .3rem;
}

.mobileTeam .teamUl .img_box{
  width: 100%;
  height: auto;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  border: .02rem solid #fff;
}

.mobileTeam .teamUl .img_box img{
  width: 100%;
  height: 100%;
  transition: none;
}
.mobileTeam .teamUl li:hover .img_box img{
  transform: none;
}

.mobileTeam .teamUl .name{
  text-align: center;
  color: #fff;
  word-break: break-all;
  margin-top: .2rem;
  line-height: 1.2;
  font-size: .24rem;
  font-family: ARFont;
  font-weight: 900;
}

.mobileTeam .teamUl .introduce{
  font-size: .18rem;
  color: #fff;
  font-family: BLFont;
  text-align: center;
}