/* .content .title {
  margin: 0;
  font-size: .6rem;
  line-height: 1.2rem;
} */

/* .mintBtn {
  margin-top: .5rem;
  width: 100%;
} */

.connectWalltBtn {
  max-width: 6.26rem;
  width: 90%;
  border: none;
  line-height: 1;
  color: #000;
  border: 0.04rem solid #AEA0FF;
  background-color: #AEA0FF;
  font-family: MWFont;
  display: flex;
  align-items: center;
  justify-content: center;
  height: .92rem;
  font-size: .24rem;
  line-height: .36rem;
  cursor: pointer;
  transition: all .3s linear;
  margin: auto;
  border-radius: 1rem;
}

/* .ReNewPublic .mint_btn {
  border: 0.04rem solid #513995;
  background-color: #513995;
  color: #fff;
} */

.mint_btn:hover,
.mint_btn.loading {
  background-color: #000;
  border-color: #AEA0FF;
  color: #AEA0FF;
}

.mint_btn:disabled {
  cursor: pointer;
  border-color: #FF4327;
  background-color: #3E0B0B;
  color: #FF4327;
  cursor: not-allowed;
}

.web3modal-modal-lightbox {
  transition: opacity 0.1s ease-in-out 0s;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin-left: -50vw;
  top: 0px;
  left: 50%;
  z-index: 2;
  will-change: opacity;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.web3modal-modal-lightbox * {
  box-sizing: border-box !important;
}

.web3modal-modal-lightbox .web3modal-modal-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-hitbox {
  position: absolute;
  inset: 0px;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  margin: 10px;
  padding: 0px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  max-width: 800px;
  min-width: fit-content;
  max-height: 100%;
  overflow: auto;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper {
  width: 100%;
  padding: 8px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 0px;
  border: 1px solid rgba(195, 195, 195, 0.14);
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper:hover .web3modal-provider-container {
  background-color: rgba(195, 195, 195, 0.14);
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container {
  transition: background-color 0.2s ease-in-out 0s;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 24px 16px;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon {
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  overflow: visible;
  box-shadow: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon img {
  width: 100%;
  height: 100%;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-name {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-top: 0.5em;
  color: #0c0c0d;
}

.web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-description {
  width: 100%;
  font-size: 18px;
  margin: 0.333em 0px;
  color: #a9a9bc;
}


@media screen and (max-width:500px) {
  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card {
    max-width: 800px;
    grid-template-columns: 1fr;
  }

  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container {
    padding: 1vw;
  }

  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon {
    width: 8.5vw;
    height: 8.5vw;
  }

  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-name {
    font-size: 5vw;
  }

  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-description {
    font-size: 4vw;
  }
}

.changVideo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.changVideo video {
  max-width: 9.9rem;
  width: 90%;
}

.fromGroup input,
.fromGroup button {
  border: none;
}

.ReNewPublic .fromGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  font-family: DDFont;
  width: 100%;
}

.fromGroup .item {
  padding: 0 .2rem;
  width: 100%;
}

.newbanner .inputGroup {
  text-align: center;
  border: .01rem solid #aea0ff80;
  max-width: 6.26rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  line-height: 1;
  display: flex;
  box-sizing: content-box;
  margin: auto;
  border-radius: 1rem;
}

.newbanner .fromGroup .item .inputGroup button {
  width: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .6rem;
  color: rgb(255 255 255);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
}

.newbanner .fromGroup .item .inputGroup button img {
  display: block;
  width: .2rem;
}

.newbanner .fromGroup .item .inputGroup input {
  flex: 1;
  height: .92rem;
  font-family: ARFont;
  text-align: center;
  font-size: .32rem;
  color: rgba(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: .92rem;
}

.fromGroup .item .inputGroup input:focus {
  border: none;
  outline: none;
}

.fromGroup .lable {
  font-size: .24rem;
  margin-bottom: .15rem;
  text-align: left;
}

.ContentAddress {
  position: absolute;
  right: 5%;
  top: 10%;
  /* width: 2.5rem; */
  padding-right: .1rem;
  height: .66rem;
  background-color: #232033;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.04rem solid #AEA0FF;
  border-radius: 1rem;
}

.ContentAddress .icon {
  width: .54rem;
  height: .54rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141120;
  border-radius: 1rem;
}

.ContentAddress .icon img {
  width: 90%;
}

.ContentAddress .textCon {
  flex: 1;
  padding: 0 .1rem;
}

.ContentAddress .pub {
  font-size: .16rem;
  font-family: DDBFont;
  color: #fff;
}

.ContentAddress .add {
  font-size: .12rem;
  font-family: DDBFont;
  line-height: .18rem;
  color: #9794A0;
}


.newbanner {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.newbanner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.newbanner-wrapper .bg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 100%;
}

.newbanner-wrapper .bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
}

.newbanner-wrapper .bg .bg-transfram {
  width: 100%;
  height: 100%;
  transform: rotateZ(-5deg);
  position: relative;
}

.newbanner-wrapper .bg .bg-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.newbanner-wrapper .bg .pc-marqueee {
  padding-top: 15%;
  width: 100%;
}

.newbanner-wrapper .bg .pc-marqueee_top {
  background: url(../../assets/banner/banner_bg1.png) repeat-x center;
  background-size: auto 100%;
  animation: wall_scrollx 80s linear 0s infinite forwards;
}

.newbanner-wrapper .bg .pc-marqueee_bottom {
  background: url(../../assets/banner/banner_bg2.png) repeat-x center;
  background-size: auto 100%;
  animation: wall_scrollx2 80s linear 0s infinite forwards;
}

.newbanner-wrapper .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

}

.newbanner-wrapper .container .title {
  line-height: 1;
  font-size: .6rem;
  line-height: .68rem;
  font-family: MWFont;
  letter-spacing: -0.02rem;
  margin-bottom: 0;
  text-align: center;
}

.newbanner-wrapper .subject {
  /* margin: .3rem 0; */
  font-family: MWFont;
  font-size: .36rem;
  line-height: 1.2rem;
  color: #AEA0FF;
}

.newbanner-wrapper .fromGroup {
  margin-top: 0;
}

.newbanner-wrapper .mint_btn {
  border-radius: 1rem;
  width: 6.26rem;
  height: .92rem;
}

.newbanner-wrapper .ContentAddress {
  top: 13%;
}

@keyframes wall_scrollx {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -120vw;
  }
}

@keyframes wall_scrollx2 {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 120vw;
  }
}

.newbanner-wrapper .model_box .video_box {
  position: absolute;
  right: 4%;
  bottom: 13%;
  width: 2.97rem;
  height: 1.69rem;
  border: .02rem solid #fff;
}

.newbanner-wrapper .model_box .btn_pause {
  width: .95rem;
  height: .95rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


.video_model .ant-modal {
  min-width: calc(100vw - 32px);
  height: 100vh;
  overflow: hidden;
}

.video_model .ant-modal-body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_model .ant-modal-close {
  right: 0.5rem;
  top: 0.2rem;
  background: url(../../assets//images/close.png) no-repeat center;
  background-size: cover;
  transition: all 0.3s linear;
}

.video_model .ant-modal-close:hover {
  background-image: url(../../assets/images/close_active.png);
}

.video_model .ant-modal-close .ant-modal-close-icon {
  display: none;
}

.bgBlock {
  position: absolute;
  height: 6rem;
  width: 100%;
  left: 0;
  bottom: 0;
  background: linear-gradient(transparent, #000)
}