.pcRaces {
  /* padding-top: .5rem; */
  padding-bottom: 0rem;
}

.pcRaces .racesList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.1rem;
  margin-top: 1.3rem;
}

.pcRaces .racesList .item {
  width: 50%;
  padding: .1rem;
  display: inline-block;
}