.footer{
  padding-bottom: 1.5rem;
}
.foot_con{
  display: flex;
  grid-gap: 2.5vw;
  gap: 2.5vw;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .foot_con >*{
  flex: 1;
} */

.foot_con .name{
  font-family: MWFont;
  font-size: .36rem;
  text-align: right;
}

.foot_con .icon_item{
  display: flex;
  grid-gap: .1rem;
  gap: .1rem;
  align-items: center;
}